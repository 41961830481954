import React from 'react'

export const Switchto = () => {
  return (
    <div className='container-fluid'>
        <div className='row'>
            <div className='col'>
                <button className='btn btn-sm rounded-1 text-uppercase fw-semibold px-3 py-2 text-white border-0 float-end 'style={{backgroundColor:'#7267ef',fontSize:'12px'}}>Switch to</button>
            </div>
        </div>
    </div>
  )
}
export default Switchto